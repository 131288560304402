import Page from 'components/Page';
import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap';
import axios from 'axios';
import StorageService from '../services/StorageService';
import { Redirect } from 'react-router';
import SessionExpiredModal from '../components/SessionExpiredModal';

class PendingUserPage extends React.Component {
  state = {
    hidden: false,
    show: false,
    pendingUserData: [],
    username: '',
    email: '',
    contact: '',
    company: '',
    date: '',
    reason: '',
    sessionPopup: false,
  };

  toggle = user => {
    this.setState({
      show: !this.state.show,
      username: user.user_name,
      email: user.user_id,
      contact: user.contact_no,
      company: user.company_name,
      date: user.created_at,
      reason: user.reason_for_using,
    });
  };

  approve = id => {
    const session_key = StorageService.getSession('user_info').session_key;
    const data = {
      session_key: session_key,
      client_id: id,
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/approve_user.php', data)
      .then(response => {
        this.setState({ hidden: false });
        const result = response.data;

        if (result.error == 'true' && result.message == 'Bad Request') {
          this.setState({
            sessionPopup: !this.state.sessionPopup,
          });
        }

        if (result.error == 'true' && result.message != 'Success') {
          alert('Failed to approve. Please try again.');
          return;
        }
        alert('User has been approved successfully.');
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  };

  delete = id => {
    const session_key = StorageService.getSession('user_info').session_key;
    const data = {
      session_key: session_key,
      client_id: id,
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/delete_user.php', data)
      .then(response => {
        this.setState({ hidden: false });
        const result = response.data;

        if (result.error == 'true' && result.message == 'Bad Request') {
          this.setState({
            sessionPopup: !this.state.sessionPopup,
          });
        }

        if (result.error == 'true' && result.message != 'Success') {
          alert('Failed to delete. Please try again.');
          return;
        }
        alert('Record deleted successfully.');
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  };

  getData() {
    document.body.classList.add('no-sroll');
    const session_key = StorageService.getSession('user_info').session_key;
    const data = {
      session_key: session_key,
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/get_pending_user.php', data)
      .then(response => {
        document.body.classList.remove('no-sroll');
        const result = response.data;
        if (result.error == 'true' && result.message == 'Bad Request') {
          this.setState({
            sessionPopup: !this.state.sessionPopup,
          });
        }

        this.setState({ pendingUserData: response.data.data, hidden: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleRedirect = () => {
    StorageService.clearSession('user_info');
    this.props.history.push('/login');
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const { username, email, contact, company, date, reason } = this.state;
    const externalCloseBtn = (
      <button
        className="close"
        style={{
          position: 'absolute',
          top: '15px',
          right: '20px',
          fontSize: '3rem',
          fontWeight: '400',
          color: '#fff',
          textShadow: 'none',
          opacity: '1',
        }}
        onClick={this.toggle}
      >
        &times;
      </button>
    );

    const current_user = StorageService.getSession('user_info');

    if (current_user.type === 'Respondent') {
      return <Redirect to="/" />;
    }

    return (
      <Page
        title="Pending Respondent"
        breadcrumbs={[{ name: 'Settings', active: true }]}
      >
        <SessionExpiredModal
          isShow={this.state.sessionPopup}
          handleRedirect={this.handleRedirect}
        />
        <div className={'loading ' + (this.state.hidden ? '' : 'hidden')}>
          <Spinner type="grow" color="secondary" />
        </div>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <Card>
              <CardHeader>Pending User</CardHeader>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Contact</th>
                      <th>Company</th>
                      <th>Reason for using</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.pendingUserData.length > 0 &&
                      this.state.pendingUserData.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{user.user_name}</td>
                            <td>
                              {user.user_id}
                              <br />
                              {user.contact_no}
                            </td>
                            <td>{user.company_name}</td>
                            <td>{user.reason_for_using}</td>
                            <td>
                              <ButtonGroup>
                                <Button
                                  color="secondary"
                                  onClick={() => this.toggle(user)}
                                >
                                  View
                                </Button>
                                <Button
                                  color="info"
                                  onClick={() => this.approve(user.user_id)}
                                >
                                  Approve
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => this.delete(user.user_id)}
                                >
                                  Delete
                                </Button>
                              </ButtonGroup>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <Modal
                  isOpen={this.state.show}
                  toggle={this.toggle}
                  size="md"
                  backdrop="static"
                  external={externalCloseBtn}
                  centered
                >
                  <ModalBody>
                    <Table borderless className="h-table">
                      <tbody>
                        <tr>
                          <td>Name:</td>
                          <td>{username}</td>
                        </tr>
                        <tr>
                          <td>Company:</td>
                          <td>{company}</td>
                        </tr>
                        <tr>
                          <td>Contact:</td>
                          <td>
                            {email} / {contact}
                          </td>
                        </tr>
                        <tr>
                          <td>Reason for using:</td>
                          <td>{reason}</td>
                        </tr>
                        <tr>
                          <td>Registered at:</td>
                          <td>{date}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default PendingUserPage;
