import Page from 'components/Page';
import UserForm from 'components/UserForm';
import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap';
import axios from 'axios';
import StorageService from '../services/StorageService';
import { Redirect } from 'react-router';
import SessionExpiredModal from '../components/SessionExpiredModal';

class HomePage extends React.Component {
  _isMounted = false;
  state = {
    hidden: true,
    show: false,
    addUserVisible: false,
    userData: [],
    sessionPopup: false,
  };

  toggle = () => {
    this.setState({
      show: !this.state.show,
    });
    this.getData();
  };

  delete = id => {
    const session_key = StorageService.getSession('user_info').session_key;
    const data = {
      session_key: session_key,
      user_id: id,
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/delete_registered_user.php', data)
      .then(response => {
        this.setState({ hidden: false });
        const result = response.data;

        if (result.error == 'true' && result.message == 'Bad Request') {
          this.setState({
            sessionPopup: !this.state.sessionPopup,
          });
        }

        console.log(result);

        if (result.error == 'true' && result.message != 'Success') {
          alert('Failed to delete. Please try again.');
          return;
        }
        alert('Record deleted successfully.');
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  };

  getData() {
    document.body.classList.add('no-sroll');
    const session_key = StorageService.getSession('user_info').session_key;
    const data = {
      session_key: session_key,
    };

    this.setState({
      hidden: true,
    });

    const type = StorageService.getSession('user_info').type;

    if (type == 'Superadmin' || type == 'DP Admin') {
      this.setState({
        addUserVisible: !this.state.addUserVisible,
      });
    }

    axios
      .post('https://www.projanalysis.com/api/get_user.php', data)
      .then(response => {
        document.body.classList.remove('no-sroll');
        const result = response.data;
        if (result.error == 'true' && result.message == 'Bad Request') {
          this.setState({
            sessionPopup: !this.state.sessionPopup,
          });
        }

        if (this._isMounted) {
          this.setState({ userData: response.data.data, hidden: false });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleRedirect = () => {
    StorageService.clearSession('user_info');
    this.props.history.push('/login');
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.getData();
  }

  render() {
    //console.log(StorageService.getSession('user_info').name);
    const current_user = StorageService.getSession('user_info');

    if (current_user.type === 'Respondent') {
      return <Redirect to="/" />;
    }

    const externalCloseBtn = (
      <button
        className="close"
        style={{
          position: 'absolute',
          top: '15px',
          right: '20px',
          fontSize: '3rem',
          fontWeight: '400',
          color: '#fff',
          textShadow: 'none',
          opacity: '1',
        }}
        onClick={this.toggle}
      >
        &times;
      </button>
    );

    return (
      <Page title="DP User" breadcrumbs={[{ name: 'Settings', active: true }]}>
        <SessionExpiredModal
          isShow={this.state.sessionPopup}
          handleRedirect={this.handleRedirect}
        />
        <div className={'loading ' + (this.state.hidden ? '' : 'hidden')}>
          <Spinner type="grow" color="secondary" />
        </div>
        {this.state.addUserVisible && (
          <Row>
            <Col lg={12} md={12} className="text-right mb-3">
              <Button color="secondary" onClick={this.toggle}>
                Add DP User
              </Button>
            </Col>
          </Row>
        )}

        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <Card>
              <CardHeader>User List</CardHeader>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Email</th>
                      <th>Name</th>
                      <th>Type</th>
                      {current_user.type === 'Superadmin' && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.userData.length > 0 &&
                      this.state.userData.map((user, index) => {
                        return (
                          <tr
                            key={index}
                            style={{
                              backgroundColor:
                                current_user.email == user.host_id
                                  ? '#e2e2e2'
                                  : '',
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>{user.host_id}</td>
                            <td>{user.host_name}</td>
                            <td>{user.type}</td>
                            {current_user.type === 'Superadmin' && (
                              <td>
                                <ButtonGroup>
                                  <Button
                                    color="primary"
                                    onClick={() => this.delete(user.host_id)}
                                  >
                                    Delete
                                  </Button>
                                </ButtonGroup>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <Modal
                  isOpen={this.state.show}
                  toggle={this.toggle}
                  size="sm"
                  backdrop="static"
                  //backdropClassName="modal-backdrop-light"
                  external={externalCloseBtn}
                  centered
                >
                  <ModalBody>
                    <UserForm
                      history={this.props.history}
                      toggleModal={this.toggle}
                    />
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default HomePage;
