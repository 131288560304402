import logo200Image from 'assets/img/logo/dp_logo.jpeg';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import { FaGithub } from 'react-icons/fa';
import {
  MdAccountCircle,
  MdBorderAll,
  MdDashboard,
  MdKeyboardArrowDown,
  MdAssignment,
  MdSettings,
  MdTimeline,
  MdUpdate,
  MdSwapVert,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';
import StorageService from '../../services/StorageService';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const settingsPages = [
  {
    to: '/system-user',
    name: 'DP User',
    exact: false,
    Icon: MdAccountCircle,
  },
  {
    to: '/pending-user',
    name: 'Pending Respondent',
    exact: false,
    Icon: MdUpdate,
  },
  {
    to: '/settings-limit',
    name: 'Survey Limit',
    exact: false,
    Icon: MdSwapVert,
  },
];

const navItems = [
  { to: '/home', name: 'Dashboard', exact: false, Icon: MdDashboard },
  {
    to: '/questionnaire',
    name: 'Questionnaire',
    exact: false,
    Icon: MdAssignment,
  },
  { to: '/kpi-guide', name: 'Kpi Guides', exact: false, Icon: MdTimeline },
];

const hostNavItems = [
  { to: '/home', name: 'Dashboard', exact: false, Icon: MdDashboard },
  // {
  //   to: '/survey-question',
  //   name: 'Questionnaire',
  //   exact: false,
  //   Icon: MdAssignment,
  // },
  { to: '/weight', name: 'Weight', exact: false, Icon: MdBorderAll },
  // {
  //   to: '/weight-score',
  //   name: 'Raw Min & Max',
  //   exact: false,
  //   Icon: MdBorderAll,
  // },
  { to: '/kpi-guide', name: 'Kpi Guides', exact: false, Icon: MdTimeline },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenPages: false,
    isOpenKpiPages: false,
    isOpenSettingsPages: true,
    isHost: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  checkPermission() {
    const permission_type = StorageService.getSession('user_info').type;
    if (permission_type == 'Respondent') {
      this.setState({
        isHost: !this.state.isHost,
      });
    }
  }

  componentDidMount() {
    this.checkPermission();
  }

  render() {
    const { isHost } = this.state;
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logo200Image}
                width="50"
                height="auto"
                className="pr-2"
                alt=""
              />
              <span className="sitename">ProjAnalysis</span>
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {isHost
              ? hostNavItems.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))
              : navItems.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}

            {isHost && (
              <React.Fragment>
                <NavItem
                  className={bem.e('nav-item')}
                  onClick={this.handleClick('SettingsPages')}
                >
                  <BSNavLink className={bem.e('nav-item-collapse')}>
                    <div className="d-flex">
                      <MdSettings className={bem.e('nav-item-icon')} />
                      <span className="">SETTINGS</span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e('nav-item-icon')}
                      style={{
                        padding: 0,
                        transform: this.state.isOpenSettingsPages
                          ? 'rotate(0deg)'
                          : 'rotate(-90deg)',
                        transitionDuration: '0.3s',
                        transitionProperty: 'transform',
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={this.state.isOpenSettingsPages}>
                  {settingsPages.map(({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className="text-uppercase"
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <Icon className={bem.e('nav-item-icon')} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  ))}
                </Collapse>
              </React.Fragment>
            )}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
