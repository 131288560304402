import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import AuthPage from 'pages/AuthPage';
import ChangePasswordPage from 'pages/ChangePasswordPage';
// pages
import SurveyPage from 'pages/SurveyPage';
import StatisticPage from 'pages/StatisticPage';
import SurveyQuestionPage from 'pages/SurveyQuestionPage';
import WeightPage from 'pages/WeightPage';
import KpiPage from 'pages/KpiPage';
import WeightScorePage from 'pages/WeightScorePage';
import HomePage from 'pages/HomePage';
import SystemUserPage from 'pages/SystemUserPage';
import PendingUserPage from 'pages/PendingUserPage';
import SurveyTakenLimitPage from 'pages/SurveyTakenLimitPage';
import RiskScorePage from 'pages/RiskScorePage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import StorageService from 'services/StorageService';

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const ProtectedRoute = ({ component: Comp, path, ...rest }) => {
  return StorageService.getSession('user_info') ? (
    <LayoutRoute
      exact
      layout={MainLayout}
      component={Comp}
      path={path}
      {...rest}
    />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  );
};

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <ProtectedRoute
              exact
              path="/"
              layout={MainLayout}
              component={props => <HomePage {...props} />}
            />
            <ProtectedRoute
              exact
              path="/survey-question"
              layout={MainLayout}
              component={SurveyQuestionPage}
            />
            <ProtectedRoute
              exact
              path="/questionnaire"
              layout={MainLayout}
              component={SurveyPage}
            />
            <ProtectedRoute
              exact
              path="/statistics"
              layout={MainLayout}
              component={props => <StatisticPage {...props} />}
            />
            <ProtectedRoute
              exact
              path="/risk-score"
              layout={MainLayout}
              component={RiskScorePage}
            />
            <ProtectedRoute
              exact
              path="/weight"
              layout={MainLayout}
              component={WeightPage}
            />
            <ProtectedRoute
              exact
              path="/weight-score"
              layout={MainLayout}
              component={WeightScorePage}
            />
            <ProtectedRoute
              exact
              path="/kpi-guide"
              layout={MainLayout}
              component={KpiPage}
            />
            <ProtectedRoute
              exact
              path="/system-user"
              layout={MainLayout}
              component={SystemUserPage}
            />
            <ProtectedRoute
              exact
              path="/settings-limit"
              layout={MainLayout}
              component={SurveyTakenLimitPage}
            />
            <ProtectedRoute
              exact
              path="/pending-user"
              layout={MainLayout}
              component={PendingUserPage}
            />
            <ProtectedRoute
              exact
              path="/change-password"
              layout={MainLayout}
              component={ChangePasswordPage}
            />
            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />
            <LayoutRoute
              exact
              path="/signup"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_SIGNUP} />
              )}
            />
            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
