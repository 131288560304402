import React from 'react';
import Page from 'components/Page';
import { HorizontalBar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import Legend from 'components/ChartLegend';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Spinner,
} from 'reactstrap';
import axios from 'axios';
import StorageService from '../services/StorageService';
import { Redirect } from 'react-router';
import SessionExpiredModal from '../components/SessionExpiredModal';

const YAXES = ['1', '2', '3', '4'];

const genData = (moreData = {}) => {
  return {
    labels: YAXES,
    datasets: [
      {
        data: [0, 0, 0, 0],
        ...moreData,
      },
    ],
  };
};

class StatisticPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      respondentData: [],
      redirect: false,
      sessionPopup: false,
    };
  }

  getData(data) {
    const info = {
      session_key: StorageService.getSession('user_info').session_key,
      project_id: data,
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/get_result.php', info)
      .then(response => {
        const result = response.data;
        if (result.error == 'true' && result.message == 'Bad Request') {
          this.setState({
            sessionPopup: !this.state.sessionPopup,
          });
        }
        this.setState({ respondentData: response.data.data, hidden: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleRedirect = () => {
    StorageService.clearSession('user_info');
    this.props.history.push('/login');
  };

  componentDidMount() {
    if (this.props.history.location.state == undefined) {
      this.setState({ redirect: true });
    } else {
      const project = this.props.history.location.state.data;
      this.getData(project.project_id);
    }
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to="/" />;
    }

    return (
      <Page
        className="StatisticPage"
        title="Statistic"
        breadcrumbs={[{ name: 'Statistic', active: true }]}
      >
        <SessionExpiredModal
          isShow={this.state.sessionPopup}
          handleRedirect={this.handleRedirect}
        />
        <div className={'loading ' + (this.state.hidden ? '' : 'hidden')}>
          <Spinner type="grow" color="secondary" />
        </div>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <CardHeader>Respondent Information</CardHeader>
              <CardBody>
                {this.props.history.location.state != undefined && (
                  <Table borderless size="sm" className="h-table">
                    <tbody>
                      <tr>
                        <td>Name:</td>
                        <td>{this.props.history.location.state.data.name}</td>
                      </tr>
                      <tr>
                        <td>Email:</td>
                        <td>{this.props.history.location.state.data.email}</td>
                      </tr>
                      <tr>
                        <td>Project:</td>
                        <td>
                          {this.props.history.location.state.data.project_name}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {this.state.respondentData[0] ? (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Card>
                <CardHeader>
                  TIME{' '}
                  <span style={{ textTransform: 'none' }}>
                    ({this.state.respondentData[0].use_for})
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <Legend />
                      <HorizontalBar
                        data={genData()}
                        options={{
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  beginAtZero: false,
                                  display: true,
                                  min:
                                    parseFloat(
                                      this.state.respondentData[0].score[0]
                                        .value,
                                    ) - 5,
                                  max:
                                    parseFloat(
                                      this.state.respondentData[0].score[4]
                                        .value,
                                    ) + 5,
                                  callback(value, index) {
                                    if (Math.floor(value) === value) {
                                      return value;
                                    }
                                  },
                                },
                              },
                            ],
                            yAxes: [
                              {
                                scaleLabel: {
                                  display: false,
                                },
                                ticks: {
                                  beginAtZero: false,
                                  display: false,
                                },
                              },
                            ],
                          },
                          annotation: {
                            annotations: [
                              {
                                drawTime: 'afterDatasetsDraw',
                                id: 'tm_vline',
                                type: 'line',
                                mode: 'vertical',
                                scaleID: 'x-axis-0',
                                value: parseFloat(
                                  Math.round(
                                    this.state.respondentData[0].result * 1000,
                                  ) / 1000,
                                ).toFixed(2),
                                borderColor: 'red',
                                borderWidth: 2,
                                label: {
                                  content: parseFloat(
                                    Math.round(
                                      this.state.respondentData[0].result *
                                        1000,
                                    ) / 1000,
                                  ).toFixed(2),
                                  enabled: true,
                                  position: 'top',
                                },
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'tm_first',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[0].score[0]
                                  .value,
                                xMax: this.state.respondentData[0].score[1]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(69, 182, 73, 0.3)',
                                borderColor: 'rgb(69, 182, 73)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'tm_second',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[0].score[1]
                                  .value,
                                xMax: this.state.respondentData[0].score[2]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(40, 121, 187, 0.3)',
                                borderColor: 'rgb(40, 121, 187)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'tm_third',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[0].score[2]
                                  .value,
                                xMax: this.state.respondentData[0].score[3]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(255, 215, 0, 0.3)',
                                borderColor: 'rgb(255, 215, 0)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'tm_fourth',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[0].score[3]
                                  .value,
                                xMax: this.state.respondentData[0].score[4]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(49, 61, 76, 0.3)',
                                borderColor: 'rgb(49, 61, 76)',
                                borderWidth: 1,
                              },
                            ],
                          },
                        }}
                      />
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <Table bordered size="sm" className="table-3">
                        <thead>
                          <tr>
                            <th>0</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {this.state.respondentData[0] &&
                              this.state.respondentData[0].score.map(
                                (item, index) => {
                                  return (
                                    <td key={index}>
                                      {parseFloat(
                                        Math.round(item.value * 1000) / 1000,
                                      ).toFixed(3)}
                                    </td>
                                  );
                                },
                              )}
                          </tr>
                        </tbody>
                      </Table>
                      <div className="box-wrapper-1">
                        <span>
                          <b>Result:</b>{' '}
                          {parseFloat(
                            Math.round(
                              this.state.respondentData[0].result * 1000,
                            ) / 1000,
                          ).toFixed(2)}
                        </span>
                        <br />
                        <span>KPI: {this.state.respondentData[0].kpi}</span>
                        <div className="inner-box-wrapper-1">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.respondentData[0].desc,
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {this.state.respondentData[1] ? (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Card>
                <CardHeader>
                  COST{' '}
                  <span style={{ textTransform: 'none' }}>
                    ({this.state.respondentData[1].use_for})
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <Legend />
                      <HorizontalBar
                        data={genData()}
                        options={{
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  beginAtZero: false,
                                  display: true,
                                  min:
                                    parseFloat(
                                      this.state.respondentData[1].score[0]
                                        .value,
                                    ) - 5,
                                  max:
                                    parseFloat(
                                      this.state.respondentData[1].score[4]
                                        .value,
                                    ) + 5,
                                  callback(value, index) {
                                    if (Math.floor(value) === value) {
                                      return value;
                                    }
                                  },
                                },
                              },
                            ],
                            yAxes: [
                              {
                                scaleLabel: {
                                  display: false,
                                },
                                ticks: {
                                  beginAtZero: false,
                                  display: false,
                                },
                              },
                            ],
                          },
                          annotation: {
                            annotations: [
                              {
                                drawTime: 'afterDatasetsDraw',
                                id: 'cost_vline',
                                type: 'line',
                                mode: 'vertical',
                                scaleID: 'x-axis-0',
                                value: parseFloat(
                                  Math.round(
                                    this.state.respondentData[1].result * 1000,
                                  ) / 1000,
                                ).toFixed(2),
                                borderColor: 'red',
                                borderWidth: 2,
                                label: {
                                  content: parseFloat(
                                    Math.round(
                                      this.state.respondentData[1].result *
                                        1000,
                                    ) / 1000,
                                  ).toFixed(2),
                                  enabled: true,
                                  position: 'top',
                                },
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'cost_first',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[1].score[0]
                                  .value,
                                xMax: this.state.respondentData[1].score[1]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(69, 182, 73, 0.3)',
                                borderColor: 'rgb(69, 182, 73)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'cost_second',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[1].score[1]
                                  .value,
                                xMax: this.state.respondentData[1].score[2]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(40, 121, 187, 0.3)',
                                borderColor: 'rgb(40, 121, 187)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'cost_third',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[1].score[2]
                                  .value,
                                xMax: this.state.respondentData[1].score[3]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(255, 215, 0, 0.3)',
                                borderColor: 'rgb(255, 215, 0)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'cost_fourth',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[1].score[3]
                                  .value,
                                xMax: this.state.respondentData[1].score[4]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(49, 61, 76, 0.3)',
                                borderColor: 'rgb(49, 61, 76)',
                                borderWidth: 1,
                              },
                            ],
                          },
                        }}
                      />
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <Table bordered size="sm" className="table-3">
                        <thead>
                          <tr>
                            <th>0</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {this.state.respondentData[1] &&
                              this.state.respondentData[1].score.map(
                                (item, index) => {
                                  return (
                                    <td key={index}>
                                      {parseFloat(
                                        Math.round(item.value * 1000) / 1000,
                                      ).toFixed(3)}
                                    </td>
                                  );
                                },
                              )}
                          </tr>
                        </tbody>
                      </Table>
                      <div className="box-wrapper-1">
                        <span>
                          <b>Result:</b>{' '}
                          {parseFloat(
                            Math.round(
                              this.state.respondentData[1].result * 1000,
                            ) / 1000,
                          ).toFixed(2)}
                        </span>
                        <br />
                        <span>KPI: {this.state.respondentData[1].kpi}</span>
                        <div className="inner-box-wrapper-1">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.respondentData[1].desc,
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {this.state.respondentData[2] ? (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Card>
                <CardHeader>
                  QUALITY{' '}
                  <span style={{ textTransform: 'none' }}>
                    ({this.state.respondentData[2].use_for})
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <Legend />
                      <HorizontalBar
                        data={genData()}
                        options={{
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  beginAtZero: false,
                                  display: true,
                                  min:
                                    parseFloat(
                                      this.state.respondentData[2].score[0]
                                        .value,
                                    ) - 5,
                                  max:
                                    parseFloat(
                                      this.state.respondentData[2].score[4]
                                        .value,
                                    ) + 5,
                                  callback(value, index) {
                                    if (Math.floor(value) === value) {
                                      return value;
                                    }
                                  },
                                },
                              },
                            ],
                            yAxes: [
                              {
                                scaleLabel: {
                                  display: false,
                                },
                                ticks: {
                                  beginAtZero: false,
                                  display: false,
                                },
                              },
                            ],
                          },
                          annotation: {
                            annotations: [
                              {
                                drawTime: 'afterDatasetsDraw',
                                id: 'qlty_vline',
                                type: 'line',
                                mode: 'vertical',
                                scaleID: 'x-axis-0',
                                value: parseFloat(
                                  Math.round(
                                    this.state.respondentData[2].result * 1000,
                                  ) / 1000,
                                ).toFixed(2),
                                borderColor: 'red',
                                borderWidth: 2,
                                label: {
                                  content: parseFloat(
                                    Math.round(
                                      this.state.respondentData[2].result *
                                        1000,
                                    ) / 1000,
                                  ).toFixed(2),
                                  enabled: true,
                                  position: 'top',
                                },
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_first',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[2].score[0]
                                  .value,
                                xMax: this.state.respondentData[2].score[1]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(69, 182, 73, 0.3)',
                                borderColor: 'rgb(69, 182, 73)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_second',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[2].score[1]
                                  .value,
                                xMax: this.state.respondentData[2].score[2]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(40, 121, 187, 0.3)',
                                borderColor: 'rgb(40, 121, 187)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_third',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[2].score[2]
                                  .value,
                                xMax: this.state.respondentData[2].score[3]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(255, 215, 0, 0.3)',
                                borderColor: 'rgb(255, 215, 0)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_fourth',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[2].score[3]
                                  .value,
                                xMax: this.state.respondentData[2].score[4]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(49, 61, 76, 0.3)',
                                borderColor: 'rgb(49, 61, 76)',
                                borderWidth: 1,
                              },
                            ],
                          },
                        }}
                      />
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <Table bordered size="sm" className="table-3">
                        <thead>
                          <tr>
                            <th>0</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {this.state.respondentData[2] &&
                              this.state.respondentData[2].score.map(
                                (item, index) => {
                                  return (
                                    <td key={index}>
                                      {parseFloat(
                                        Math.round(item.value * 1000) / 1000,
                                      ).toFixed(3)}
                                    </td>
                                  );
                                },
                              )}
                          </tr>
                        </tbody>
                      </Table>
                      <div className="box-wrapper-1">
                        <span>
                          <b>Result:</b>{' '}
                          {parseFloat(
                            Math.round(
                              this.state.respondentData[2].result * 1000,
                            ) / 1000,
                          ).toFixed(2)}
                        </span>
                        <br />
                        <span>KPI: {this.state.respondentData[2].kpi}</span>
                        <div className="inner-box-wrapper-1">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.respondentData[2].desc,
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {this.state.respondentData[3] ? (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Card>
                <CardHeader>
                  Brand Reputation{' '}
                  <span style={{ textTransform: 'none' }}>
                    ({this.state.respondentData[3].use_for})
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <Legend />
                      <HorizontalBar
                        data={genData()}
                        options={{
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  beginAtZero: false,
                                  display: true,
                                  min:
                                    parseFloat(
                                      this.state.respondentData[3].score[0]
                                        .value,
                                    ) - 5,
                                  max:
                                    parseFloat(
                                      this.state.respondentData[3].score[4]
                                        .value,
                                    ) + 5,
                                  callback(value, index) {
                                    if (Math.floor(value) === value) {
                                      return value;
                                    }
                                  },
                                },
                              },
                            ],
                            yAxes: [
                              {
                                scaleLabel: {
                                  display: false,
                                },
                                ticks: {
                                  beginAtZero: false,
                                  display: false,
                                },
                              },
                            ],
                          },
                          annotation: {
                            annotations: [
                              {
                                drawTime: 'afterDatasetsDraw',
                                id: 'qlty_vline',
                                type: 'line',
                                mode: 'vertical',
                                scaleID: 'x-axis-0',
                                value: parseFloat(
                                  Math.round(
                                    this.state.respondentData[3].result * 1000,
                                  ) / 1000,
                                ).toFixed(2),
                                borderColor: 'red',
                                borderWidth: 2,
                                label: {
                                  content: parseFloat(
                                    Math.round(
                                      this.state.respondentData[3].result *
                                        1000,
                                    ) / 1000,
                                  ).toFixed(2),
                                  enabled: true,
                                  position: 'top',
                                },
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_first',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[3].score[0]
                                  .value,
                                xMax: this.state.respondentData[3].score[1]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(69, 182, 73, 0.3)',
                                borderColor: 'rgb(69, 182, 73)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_second',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[3].score[1]
                                  .value,
                                xMax: this.state.respondentData[3].score[2]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(40, 121, 187, 0.3)',
                                borderColor: 'rgb(40, 121, 187)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_third',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[3].score[2]
                                  .value,
                                xMax: this.state.respondentData[3].score[3]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(255, 215, 0, 0.3)',
                                borderColor: 'rgb(255, 215, 0)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_fourth',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[3].score[3]
                                  .value,
                                xMax: this.state.respondentData[3].score[4]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(49, 61, 76, 0.3)',
                                borderColor: 'rgb(49, 61, 76)',
                                borderWidth: 1,
                              },
                            ],
                          },
                        }}
                      />
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <Table bordered size="sm" className="table-3">
                        <thead>
                          <tr>
                            <th>0</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {this.state.respondentData[3] &&
                              this.state.respondentData[3].score.map(
                                (item, index) => {
                                  return (
                                    <td key={index}>
                                      {parseFloat(
                                        Math.round(item.value * 1000) / 1000,
                                      ).toFixed(3)}
                                    </td>
                                  );
                                },
                              )}
                          </tr>
                        </tbody>
                      </Table>
                      <div className="box-wrapper-1">
                        <span>
                          <b>Result:</b>{' '}
                          {parseFloat(
                            Math.round(
                              this.state.respondentData[3].result * 1000,
                            ) / 1000,
                          ).toFixed(2)}
                        </span>
                        <br />
                        <span>KPI: {this.state.respondentData[3].kpi}</span>
                        <div className="inner-box-wrapper-1">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.respondentData[3].desc,
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {this.state.respondentData[4] ? (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Card>
                <CardHeader>
                  Returns / Benefits realisation{' '}
                  <span style={{ textTransform: 'none' }}>
                    ({this.state.respondentData[4].use_for})
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <Legend />
                      <HorizontalBar
                        data={genData()}
                        options={{
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  beginAtZero: false,
                                  display: true,
                                  min:
                                    parseFloat(
                                      this.state.respondentData[4].score[0]
                                        .value,
                                    ) - 5,
                                  max:
                                    parseFloat(
                                      this.state.respondentData[4].score[4]
                                        .value,
                                    ) + 5,
                                  callback(value, index) {
                                    if (Math.floor(value) === value) {
                                      return value;
                                    }
                                  },
                                },
                              },
                            ],
                            yAxes: [
                              {
                                scaleLabel: {
                                  display: false,
                                },
                                ticks: {
                                  beginAtZero: false,
                                  display: false,
                                },
                              },
                            ],
                          },
                          annotation: {
                            annotations: [
                              {
                                drawTime: 'afterDatasetsDraw',
                                id: 'qlty_vline',
                                type: 'line',
                                mode: 'vertical',
                                scaleID: 'x-axis-0',
                                value: parseFloat(
                                  Math.round(
                                    this.state.respondentData[4].result * 1000,
                                  ) / 1000,
                                ).toFixed(2),
                                borderColor: 'red',
                                borderWidth: 2,
                                label: {
                                  content: parseFloat(
                                    Math.round(
                                      this.state.respondentData[4].result *
                                        1000,
                                    ) / 1000,
                                  ).toFixed(2),
                                  enabled: true,
                                  position: 'top',
                                },
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_first',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[4].score[0]
                                  .value,
                                xMax: this.state.respondentData[4].score[1]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(69, 182, 73, 0.3)',
                                borderColor: 'rgb(69, 182, 73)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_second',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[4].score[1]
                                  .value,
                                xMax: this.state.respondentData[4].score[2]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(40, 121, 187, 0.3)',
                                borderColor: 'rgb(40, 121, 187)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_third',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[4].score[2]
                                  .value,
                                xMax: this.state.respondentData[4].score[3]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(255, 215, 0, 0.3)',
                                borderColor: 'rgb(255, 215, 0)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_fourth',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[4].score[3]
                                  .value,
                                xMax: this.state.respondentData[4].score[4]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(49, 61, 76, 0.3)',
                                borderColor: 'rgb(49, 61, 76)',
                                borderWidth: 1,
                              },
                            ],
                          },
                        }}
                      />
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <Table bordered size="sm" className="table-3">
                        <thead>
                          <tr>
                            <th>0</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {this.state.respondentData[4] &&
                              this.state.respondentData[4].score.map(
                                (item, index) => {
                                  return (
                                    <td key={index}>
                                      {parseFloat(
                                        Math.round(item.value * 1000) / 1000,
                                      ).toFixed(3)}
                                    </td>
                                  );
                                },
                              )}
                          </tr>
                        </tbody>
                      </Table>
                      <div className="box-wrapper-1">
                        <span>
                          <b>Result:</b>{' '}
                          {parseFloat(
                            Math.round(
                              this.state.respondentData[4].result * 1000,
                            ) / 1000,
                          ).toFixed(2)}
                        </span>
                        <br />
                        <span>KPI: {this.state.respondentData[4].kpi}</span>
                        <div className="inner-box-wrapper-1">
                          <p>{this.state.respondentData[4].desc}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {this.state.respondentData[5] ? (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Card>
                <CardHeader>
                  PM Index{' '}
                  <span style={{ textTransform: 'none' }}>
                    ({this.state.respondentData[5].use_for})
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <Legend />
                      <HorizontalBar
                        data={genData()}
                        options={{
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  beginAtZero: false,
                                  display: true,
                                  min:
                                    parseFloat(
                                      this.state.respondentData[5].score[0]
                                        .value,
                                    ) - 20,
                                  max:
                                    parseFloat(
                                      this.state.respondentData[5].score[4]
                                        .value,
                                    ) + 20,
                                  callback(value, index) {
                                    if (Math.floor(value) === value) {
                                      return value;
                                    }
                                  },
                                },
                              },
                            ],
                            yAxes: [
                              {
                                scaleLabel: {
                                  display: false,
                                },
                                ticks: {
                                  beginAtZero: false,
                                  display: false,
                                },
                              },
                            ],
                          },
                          annotation: {
                            annotations: [
                              {
                                drawTime: 'afterDatasetsDraw',
                                id: 'qlty_vline',
                                type: 'line',
                                mode: 'vertical',
                                scaleID: 'x-axis-0',
                                value: parseFloat(
                                  Math.round(
                                    this.state.respondentData[5].result * 1000,
                                  ) / 1000,
                                ).toFixed(2),
                                borderColor: 'red',
                                borderWidth: 2,
                                label: {
                                  content: parseFloat(
                                    Math.round(
                                      this.state.respondentData[5].result *
                                        1000,
                                    ) / 1000,
                                  ).toFixed(2),
                                  enabled: true,
                                  position: 'top',
                                },
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_first',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[5].score[0]
                                  .value,
                                xMax: this.state.respondentData[5].score[1]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(69, 182, 73, 0.3)',
                                borderColor: 'rgb(69, 182, 73)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_second',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[5].score[1]
                                  .value,
                                xMax: this.state.respondentData[5].score[2]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(40, 121, 187, 0.3)',
                                borderColor: 'rgb(40, 121, 187)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_third',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[5].score[2]
                                  .value,
                                xMax: this.state.respondentData[5].score[3]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(255, 215, 0, 0.3)',
                                borderColor: 'rgb(255, 215, 0)',
                                borderWidth: 1,
                              },
                              {
                                drawTime: 'beforeDatasetsDraw',
                                id: 'qlty_fourth',
                                type: 'box',
                                xScaleID: 'x-axis-0',
                                yScaleID: 'y-axis-0',
                                xMin: this.state.respondentData[5].score[3]
                                  .value,
                                xMax: this.state.respondentData[5].score[4]
                                  .value,
                                yMin: '1',
                                yMax: '4',
                                backgroundColor: 'rgba(49, 61, 76, 0.3)',
                                borderColor: 'rgb(49, 61, 76)',
                                borderWidth: 1,
                              },
                            ],
                          },
                        }}
                      />
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <Table bordered size="sm" className="table-3">
                        <thead>
                          <tr>
                            <th>0</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {this.state.respondentData[5] &&
                              this.state.respondentData[5].score.map(
                                (item, index) => {
                                  return (
                                    <td key={index}>
                                      {parseFloat(
                                        Math.round(item.value * 1000) / 1000,
                                      ).toFixed(3)}
                                    </td>
                                  );
                                },
                              )}
                          </tr>
                        </tbody>
                      </Table>
                      <div className="box-wrapper-1">
                        <span>
                          <b>Result:</b>{' '}
                          {parseFloat(
                            Math.round(
                              this.state.respondentData[5].result * 1000,
                            ) / 1000,
                          ).toFixed(2)}
                        </span>
                        <br />
                        <span>KPI: {this.state.respondentData[5].kpi}</span>
                        <div className="inner-box-wrapper-1">
                          <p>{this.state.respondentData[5].desc}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Page>
    );
  }
}

export default StatisticPage;
