import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Modal,
  ModalBody,
  Spinner,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from 'reactstrap';
import Page from 'components/Page';
import axios from 'axios';
import StorageService from '../services/StorageService';

class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: false,
      current_password: '',
      new_password: '',
    };
  }

  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const user_info = StorageService.getSession('user_info');
    const data = {
      session_key: user_info.session_key,
      current_password: this.state.current_password,
      new_password: this.state.new_password,
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/update_password.php', data)
      .then(response => {
        this.setState({ hidden: false });
        const result = response.data;
        if (result.error == 'true' && result.message != 'Success') {
          alert(result.message);
          return;
        }

        alert(result.message);
        StorageService.clearSession('user_info');
        this.props.history.push('/login');
      })
      .catch(error => {
        alert(error);
      });
  };

  render() {
    return (
      <Page
        title="Change Password"
        breadcrumbs={[{ name: 'Change Password', active: true }]}
      >
        <div className={'loading ' + (this.state.hidden ? '' : 'hidden')}>
          <Spinner type="grow" color="secondary" />
        </div>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <CardHeader>Change Password</CardHeader>
              <CardBody>
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup row>
                    <Label for="current_password" sm={2}>
                      Current password
                    </Label>
                    <Col sm={3}>
                      <Input
                        type="password"
                        name="current_password"
                        value={this.state.current_password}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="new_password" sm={2}>
                      New password
                    </Label>
                    <Col sm={3}>
                      <Input
                        type="password"
                        name="new_password"
                        value={this.state.new_password}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>

                  <Button>Update</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default ChangePasswordPage;
