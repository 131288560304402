import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import React from 'react';
import { MdClearAll, MdExitToApp } from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';
import StorageService from '../../services/StorageService';

const bem = bn.create('header');

class Header extends React.Component {
  state = {
    isOpenUserCardPopover: false,
    username: '',
    email: '',
    type: '',
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  changePassword = () => {
    const { children } = this.props;
    children.props.history.push('/change-password');
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  componentDidMount() {
    const user_info = StorageService.getSession('user_info');
    this.setState({
      username: user_info.name,
      email: user_info.email,
      type: user_info.type,
    });
  }

  render() {
    const { username, email, type } = this.state;

    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        {/* <Nav navbar>
          <SearchInput />
        </Nav> */}

        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click"
              />
              <div className="avatar-text">
                <span>{username}</span>
                <br />
                <span className="subtitle">{type}</span>
              </div>
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title={username}
                  subtitle={email}
                  className="border-light"
                >
                  <ListGroup flush>
                    {/* <ListGroupItem
                      tag="button"
                      action
                      className="border-light"
                      onClick={() => {
                        this.changePassword();
                        this.toggleUserCardPopover();
                      }}
                    >
                      <MdPersonPin /> Change Password
                    </ListGroupItem> */}
                    <ListGroupItem
                      tag="button"
                      action
                      className="border-light"
                      onClick={this.props.handleLogout}
                    >
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;
