import React from 'react';
import { Row, Col, Card, CardHeader, CardBody, Table } from 'reactstrap';

class RiskScorePage extends React.Component {
  render() {
    return <div></div>;
  }
}

export default RiskScorePage;
