import React from 'react';
import Page from 'components/Page';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Table,
  Button,
} from 'reactstrap';
import axios from 'axios';
import classnames from 'classnames';
import StorageService from '../services/StorageService';
import { animateScroll as scroll } from 'react-scroll';

class SurveyQuestionPage extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      queryData: [],
      hidden: false,
    };
  }

  getData = () => {
    document.body.classList.add('no-sroll');

    const session_key = StorageService.getSession('user_info').session_key;
    const data = {
      session_key: session_key,
    };

    this.setState({
      hidden: !this.state.hidden,
    });

    axios
      .post('https://www.projanalysis.com/api/get_question.php', data)
      .then(response => {
        console.log(response.data);
        document.body.classList.remove('no-sroll');
        this.setState({
          queryData: response.data.data,
          hidden: !this.state.hidden,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getData();
  }

  toggle(tab) {
    scroll.scrollToTop();
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { activeTab, hidden } = this.state;
    return (
      <Page
        className="QuestionnairePage"
        title="Questionnaire"
        breadcrumbs={[{ name: 'Questionnaire', active: true }]}
      >
        <div className={'loading ' + (hidden ? '' : 'hidden')}>
          <Spinner type="grow" color="secondary" />
        </div>
        <Row>
          <Col md={12} sm={12} xs={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === '1',
                  })}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  Feasibility
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === '2',
                  })}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  Design
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === '3',
                  })}
                  onClick={() => {
                    this.toggle('3');
                  }}
                >
                  Procurement
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === '4',
                  })}
                  onClick={() => {
                    this.toggle('4');
                  }}
                >
                  Construction
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              {this.state.queryData.length > 0 &&
                this.state.queryData.map((stage, first_index) => {
                  return (
                    <TabPane
                      tabId={first_index + 1}
                      key={first_index}
                      className={activeTab == first_index + 1 ? 'active' : ''}
                    >
                      <Card className="mb-4">
                        <CardHeader>{stage.stage_name}</CardHeader>
                        <CardBody>
                          {stage.category.map((cat, second_index) => {
                            return (
                              <div key={second_index}>
                                <p key={cat.category_id}>
                                  <strong>{cat.category_name}</strong>
                                </p>
                                <Table size="sm">
                                  <tbody>
                                    {cat.query.map((q, third_index) => {
                                      return (
                                        <React.Fragment key={third_index}>
                                          <tr>
                                            <td>{q.id}.</td>
                                            <td>{q.question_name}</td>
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td>
                                              <Table borderless>
                                                <thead>
                                                  <tr>
                                                    <th>Response</th>
                                                    <th>Scoring</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {q.choice.map(
                                                    (input, fourth_index) => {
                                                      if (input.type == 'B') {
                                                        return (
                                                          <React.Fragment
                                                            key={fourth_index}
                                                          >
                                                            {input.answer.map(
                                                              (
                                                                group,
                                                                group_index,
                                                              ) => {
                                                                return (
                                                                  <React.Fragment
                                                                    key={
                                                                      group_index
                                                                    }
                                                                  >
                                                                    {group.option.map(
                                                                      (
                                                                        choice,
                                                                        answer_index,
                                                                      ) => {
                                                                        return (
                                                                          <tr
                                                                            key={
                                                                              answer_index
                                                                            }
                                                                          >
                                                                            <td
                                                                              style={{
                                                                                maxWidth:
                                                                                  '100%',
                                                                                width:
                                                                                  '400px',
                                                                              }}
                                                                            >
                                                                              {
                                                                                choice.response
                                                                              }
                                                                            </td>
                                                                            <td>
                                                                              {
                                                                                choice.scoring
                                                                              }
                                                                            </td>
                                                                          </tr>
                                                                        );
                                                                      },
                                                                    )}
                                                                  </React.Fragment>
                                                                );
                                                              },
                                                            )}
                                                          </React.Fragment>
                                                        );
                                                      }

                                                      if (input.type == 'C') {
                                                        return (
                                                          <React.Fragment
                                                            key={fourth_index}
                                                          >
                                                            {input.answer.map(
                                                              (
                                                                choice,
                                                                answer_index,
                                                              ) => {
                                                                return (
                                                                  <tr
                                                                    key={
                                                                      answer_index
                                                                    }
                                                                  >
                                                                    <td
                                                                      style={{
                                                                        maxWidth:
                                                                          '100%',
                                                                        width:
                                                                          '400px',
                                                                      }}
                                                                    >
                                                                      {
                                                                        choice.response
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        choice.scoring
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                );
                                                              },
                                                            )}
                                                          </React.Fragment>
                                                        );
                                                      }

                                                      if (input.type == 'A') {
                                                        return (
                                                          <React.Fragment
                                                            key={fourth_index}
                                                          >
                                                            {input.answer.map(
                                                              (
                                                                choice,
                                                                answer_index,
                                                              ) => {
                                                                return (
                                                                  <tr
                                                                    key={
                                                                      answer_index
                                                                    }
                                                                  >
                                                                    <td
                                                                      style={{
                                                                        maxWidth:
                                                                          '100%',
                                                                        width:
                                                                          '400px',
                                                                      }}
                                                                    >
                                                                      {
                                                                        choice.response
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        choice.scoring
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                );
                                                              },
                                                            )}
                                                          </React.Fragment>
                                                        );
                                                      }
                                                    },
                                                  )}
                                                </tbody>
                                              </Table>
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </div>
                            );
                          })}
                        </CardBody>
                      </Card>
                      <>
                        {first_index != 0 && (
                          <Button
                            color="secondary"
                            onClick={() => {
                              this.toggle((parseInt(activeTab) - 1).toString());
                            }}
                          >
                            Prev -{' '}
                            {this.state.queryData[first_index - 1].stage_name}
                          </Button>
                        )}{' '}
                        {first_index + 1 < this.state.queryData.length && (
                          <Button
                            color="secondary"
                            onClick={() => {
                              this.toggle((parseInt(activeTab) + 1).toString());
                            }}
                          >
                            Next -{' '}
                            {this.state.queryData[first_index + 1].stage_name}
                          </Button>
                        )}
                      </>
                    </TabPane>
                  );
                })}
            </TabContent>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default SurveyQuestionPage;
