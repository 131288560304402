import React from 'react';
import { Row, Col, Card, CardHeader, CardBody, Table } from 'reactstrap';
import axios from 'axios';
import Page from 'components/Page';
import StorageService from '../services/StorageService';

class KpiPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      wScoreData: [],
      riskScoreData: [],
    };
  }

  getData() {
    const info = {
      session_key: StorageService.getSession('user_info').session_key,
    };
    axios
      .post('/api/get_weight_score.php', info)
      .then(response => {
        console.log(response.data);
        this.setState({ riskScoreData: response.data.data });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <Page
        className="WeightScorePage"
        title="Raw Min and Max"
        breadcrumbs={[{ name: 'Raw Min and Max', active: true }]}
      >
        <Row>
          <Col lg={6} md={12} sm={12}>
            <Card>
              <CardHeader>Raw Min</CardHeader>
              <CardBody>
                <Table size="sm" hover>
                  <thead>
                    <tr>
                      <th>Cat Code</th>
                      <th>TM</th>
                      <th>COST</th>
                      <th>QLTY</th>
                      <th>REP</th>
                      <th>RET</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.riskScoreData[0] !== undefined &&
                      this.state.riskScoreData[0].raw_min.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.cat_code}</td>
                            <td>
                              {parseFloat(
                                Math.round(item.tm * 100) / 100,
                              ).toFixed(2)}
                            </td>
                            <td>
                              {parseFloat(
                                Math.round(item.cost * 100) / 100,
                              ).toFixed(2)}
                            </td>
                            <td>
                              {parseFloat(
                                Math.round(item.qlty * 100) / 100,
                              ).toFixed(2)}
                            </td>
                            <td>
                              {parseFloat(
                                Math.round(item.rep * 100) / 100,
                              ).toFixed(2)}
                            </td>
                            <td>
                              {parseFloat(
                                Math.round(item.ret * 100) / 100,
                              ).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Minimum</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Card>
              <CardHeader>Raw Max</CardHeader>
              <CardBody>
                <Table size="sm" hover>
                  <thead>
                    <tr>
                      <th>Cat Code</th>
                      <th>TM</th>
                      <th>COST</th>
                      <th>QLTY</th>
                      <th>REP</th>
                      <th>RET</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.riskScoreData[1] !== undefined &&
                      this.state.riskScoreData[1].raw_max.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.cat_code}</td>
                            <td>
                              {parseFloat(
                                Math.round(item.tm * 100) / 100,
                              ).toFixed(2)}
                            </td>
                            <td>
                              {parseFloat(
                                Math.round(item.cost * 100) / 100,
                              ).toFixed(2)}
                            </td>
                            <td>
                              {parseFloat(
                                Math.round(item.qlty * 100) / 100,
                              ).toFixed(2)}
                            </td>
                            <td>
                              {parseFloat(
                                Math.round(item.rep * 100) / 100,
                              ).toFixed(2)}
                            </td>
                            <td>
                              {parseFloat(
                                Math.round(item.ret * 100) / 100,
                              ).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Maximum</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <CardHeader>WEIGHT SCORE BREAKDOWN</CardHeader>
              <CardBody>
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th rowSpan="2">Cat Code</th>
                      <th rowSpan="2">Query ID</th>
                      <th rowSpan="2">Response</th>
                      <th rowSpan="2">Risk Score</th>
                      <th colSpan="2">TM</th>
                      <th colSpan="2">COST</th>
                      <th colSpan="2">QLTY</th>
                      <th colSpan="2">REP</th>
                      <th colSpan="2">RET</th>
                    </tr>
                    <tr>
                      <td>W</td>
                      <td>W Score</td>
                      <td>W</td>
                      <td>W Score</td>
                      <td>W</td>
                      <td>W Score</td>
                      <td>W</td>
                      <td>W Score</td>
                      <td>W</td>
                      <td>W Score</td>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default KpiPage;
