import React from 'react';
import { Button } from 'reactstrap';

class Disclaimer extends React.Component {
  cancelRegister = () => {
    this.props.onToggleModal();
  };

  render() {
    return (
      <div className="disclaimer">
        <div>
          <ol>
            <li>
              While every effort has been made to ensure the predictions account
              for as many factors as possible based on DotProject’s experience
              and expertise, DotProjects makes no warranty on the accuracy of
              such predictions. The user is best advised to use the predictions
              wisely in conjunction with other decision making tools and to
              exercise good judgment taking into account actual business risks
              and circumstances
            </li>
            <li>
              This survey and all content related here-in remain the sole
              property of DotProjects Pte Ltd. No part of this survey, whether
              in whole or partially may be reproduced, redistributed or re-used
              in any form except for written consent from DotProjects Pte Ltd
            </li>
            <li>
              All survey data entered in ProjAnalysis may and can only be used
              by DotProjects Pte Ltd for refinement and improvement of the
              predictive model to augment and improve model accuracy
            </li>
            <li>
              To safeguard your personal data from unauthorised access,
              collection, use, disclosure, copying, modification, disposal or
              similar risks, we have introduced appropriate measures to protect
              your information that has been provided for the purpose of this
              survey. Only authorised third party service providers and agents
              can have the right to access these information, only on a
              need-to-know basis.
            </li>
          </ol>
        </div>
        <div>
          <Button color="danger" onClick={this.cancelRegister}>
            CANCEL
          </Button>{' '}
          <Button color="success" onClick={this.props.onHandleSubmit}>
            ACCEPT
          </Button>{' '}
        </div>
      </div>
    );
  }
}

export default Disclaimer;
