import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';
import StorageService from '../services/StorageService';

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      fullname: '',
      type: '',
    };
  }

  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    if (
      Object.keys(this.state.email).length == 0 ||
      Object.keys(this.state.fullname).length == 0 ||
      Object.keys(this.state.type).length == 0
    ) {
      alert('Invalid entry.');
      return;
    }

    const data = {
      session_key: StorageService.getSession('user_info').session_key,
      email: this.state.email,
      name: this.state.fullname,
      type: this.state.type,
    };

    const self = this;

    axios
      .post('https://www.projanalysis.com/api/register_admin.php', data)
      .then(response => {
        const result = response.data;

        if (result.error == 'true' && result.message != 'Success') {
          alert(result.message);
          return;
        }

        //self.props.history.push('/settings');
        alert(result.message);
        this.props.toggleModal();
      })
      .catch(error => {
        throw new Error(error);
      });
  };

  render() {
    const {
      emailLabel,
      emailInputProps,
      fullnameLabel,
      fullnameInputProps,
      userTypeLabel,
      userTypeInputProps,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for={emailLabel}>{emailLabel}</Label>
          <Input
            {...emailInputProps}
            value={this.state.email}
            onChange={this.changeHandler}
          />
        </FormGroup>
        <FormGroup>
          <Label for={fullnameLabel}>{fullnameLabel}</Label>
          <Input
            {...fullnameInputProps}
            value={this.state.fullname}
            onChange={this.changeHandler}
          />
        </FormGroup>
        <FormGroup>
          <Label for={userTypeLabel}>{userTypeLabel}</Label>
          {/* <Input
            {...userTypeInputProps}
            value={this.state.type}
            onChange={this.changeHandler}
          /> */}
          <Input
            {...userTypeInputProps}
            value={this.state.type}
            onChange={this.changeHandler}
          >
            <option value="">Select user role---</option>
            <option value="DP Admin">DP Admin</option>
            <option value="DP Staff">DP Staff</option>
          </Input>
        </FormGroup>
        <hr />
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={this.handleSubmit}
          type="submit"
        >
          Create
        </Button>
      </Form>
    );
  }
}

UserForm.propTypes = {
  emailLabel: PropTypes.string,
  emailInputProps: PropTypes.object,
  fullnameLabel: PropTypes.string,
  fullnameInputProps: PropTypes.object,
  userTypeLabel: PropTypes.string,
  userTypeInputProps: PropTypes.object,
};

UserForm.defaultProps = {
  emailLabel: 'Email',
  emailInputProps: {
    type: 'email',
    placeholder: 'user@email.com',
    name: 'email',
  },
  fullnameLabel: 'Full Name',
  fullnameInputProps: {
    type: 'text',
    placeholder: 'Full name',
    name: 'fullname',
  },
  userTypeLabel: 'User Type',
  userTypeInputProps: {
    type: 'select',
    placeholder: 'User type',
    name: 'type',
  },
};

export default UserForm;
