import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';
import StorageService from '../services/StorageService';

class ProjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    if (Object.keys(this.state.name).length == 0) {
      alert('Please enter project name.');
      return;
    }

    const data = {
      session_key: StorageService.getSession('user_info').session_key,
      client: StorageService.getSession('user_info').email,
      name: this.state.name,
    };

    axios
      .post('https://www.projanalysis.com/api/create_project.php', data)
      .then(response => {
        const result = response.data;
        if (result.error == 'true' && result.message != 'Success') {
          alert(result.message);
          return;
        }
        this.props.toggleModal();
        this.props.loadData(result.data.project_id);
      })
      .catch(error => {
        console.log(error);
      });
  };
  render() {
    const { projectNameLabel, projectNameInputProps } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for={projectNameLabel}>{projectNameLabel}</Label>
          <Input
            {...projectNameInputProps}
            value={this.state.name}
            onChange={this.changeHandler}
          />
        </FormGroup>
        <hr />
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={this.handleSubmit}
          type="submit"
        >
          Go
        </Button>
      </Form>
    );
  }
}
ProjectForm.propTypes = {
  projectNameLabel: PropTypes.string,
  projectNameInputProps: PropTypes.object,
};

ProjectForm.defaultProps = {
  projectNameLabel: 'Project Name',
  projectNameInputProps: {
    type: 'text',
    placeholder: '',
    name: 'name',
  },
};

export default ProjectForm;
