import logo200Image from 'assets/img/logo/dp_logo.jpeg';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap';
import axios from 'axios';
import StorageService from '../services/StorageService';
import Disclaimer from '../components/Disclamer';

class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      username: '',
      company: '',
      contact: '',
      reason: '',
      show: false,
    };
  }

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  toggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    const data = {
      app_key: process.env.REACT_APP_KEY,
      email: this.state.email,
      password: this.state.password,
    };

    const self = this;

    if (this.props.authState == 'LOGIN') {
      axios
        .post('https://www.projanalysis.com/api/login.php', data)
        .then(response => {
          const result = response.data;
          if (result.error == 'true' && result.message != 'Success') {
            alert(result.message);
            return;
          }
          StorageService.setSession('user_info', result.data);
          self.props.history.push('/');
        })
        .catch(error => {
          alert(error);
        });
    } else {
      this.toggle();
      if (this.state.show) {
        data.name = this.state.username;
        data.type = 'Respondent';
        data.company = this.state.company;
        data.contact = this.state.contact;
        data.reason = this.state.reason;
        axios
          .post('https://www.projanalysis.com/api/register.php', data)
          .then(response => {
            const result = response.data;
            if (result.error == 'true' && result.message != 'Success') {
              alert(result.message);
              return;
            }
            alert(
              'Registered successfully. Please wait for approval in order to login to your account.',
            );
            self.props.history.push('/login');
          })
          .catch(error => {
            alert(error);
          });
      }
    }
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      userNameLabel,
      userNameInputProps,
      companyLabel,
      companyInputProps,
      contactLabel,
      contactInputProps,
      reasonLabel,
      reasonInputProps,
      children,
      onLogoClick,
    } = this.props;

    const externalCloseBtn = (
      <button
        className="close"
        style={{
          display: 'none',
        }}
      >
        &times;
      </button>
    );

    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          {showLogo && (
            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 100, height: 'auto', cursor: 'pointer' }}
                alt="logo"
                onClick={onLogoClick}
              />
            </div>
          )}
          <FormGroup>
            <Label for={usernameLabel}>{usernameLabel}</Label>
            <Input
              {...usernameInputProps}
              value={this.state.email}
              onChange={this.changeHandler}
            />
          </FormGroup>
          {this.isLogin && (
            <FormGroup>
              <Label for={passwordLabel}>{passwordLabel}</Label>
              <Input
                {...passwordInputProps}
                value={this.state.password}
                onChange={this.changeHandler}
              />
            </FormGroup>
          )}

          {this.isSignup && (
            <>
              <FormGroup>
                <Label for={userNameLabel}>{userNameLabel}</Label>
                <Input
                  {...userNameInputProps}
                  value={this.state.username}
                  onChange={this.changeHandler}
                />
              </FormGroup>

              <FormGroup>
                <Label for={companyLabel}>{companyLabel}</Label>
                <Input
                  {...companyInputProps}
                  value={this.state.company}
                  onChange={this.changeHandler}
                />
              </FormGroup>

              <FormGroup>
                <Label for={contactLabel}>{contactLabel}</Label>
                <Input
                  {...contactInputProps}
                  value={this.state.contact}
                  onChange={this.changeHandler}
                />
              </FormGroup>

              <FormGroup>
                <Label for={reasonLabel}>{reasonLabel}</Label>
                <Input
                  {...reasonInputProps}
                  value={this.state.reason}
                  onChange={this.changeHandler}
                />
              </FormGroup>
            </>
          )}
          {/* <FormGroup check>
          <Label check>
            <Input type="checkbox" />{' '}
            {this.isSignup ? 'Agree the terms and policy' : 'Remember me'}
          </Label>
        </FormGroup> */}
          <hr />
          <Button
            size="lg"
            className="bg-gradient-theme-left border-0"
            block
            onClick={this.handleSubmit}
          >
            {this.renderButtonText()}
          </Button>

          <div className="text-center pt-1">
            <h6>or</h6>
            <h6>
              {this.isSignup ? (
                <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                  Login
                </a>
              ) : (
                <a href="#signup" onClick={this.changeAuthState(STATE_SIGNUP)}>
                  Signup
                </a>
              )}
            </h6>
          </div>

          {children}
        </Form>
        <Modal
          isOpen={this.state.show}
          toggle={this.toggle}
          size="lg"
          backdrop="static"
          external={externalCloseBtn}
          centered
        >
          <ModalBody>
            <Disclaimer
              onHandleSubmit={this.handleSubmit}
              onToggleModal={this.toggle}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  userNameLabel: PropTypes.string,
  userNameInputProps: PropTypes.object,
  companyLabel: PropTypes.string,
  companyInputProps: PropTypes.object,
  contactLabel: PropTypes.string,
  contactInputProps: PropTypes.object,
  reasonLabel: PropTypes.string,
  reasonInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
    name: 'email',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
    name: 'password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
    name: 'confirmPassword',
  },
  userNameLabel: 'Your Name',
  userNameInputProps: {
    type: 'text',
    placeholder: 'Your Name',
    name: 'username',
  },
  companyLabel: 'Company',
  companyInputProps: {
    type: 'text',
    placeholder: 'Your Company',
    name: 'company',
  },
  contactLabel: 'Contact No.',
  contactInputProps: {
    type: 'text',
    placeholder: 'Contact No.',
    name: 'contact',
  },
  reasonLabel: 'Reason for using',
  reasonInputProps: {
    type: 'text',
    placeholder: 'Please state your reason',
    name: 'reason',
  },
  onLogoClick: () => {},
};

export default AuthForm;
