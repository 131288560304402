import React from 'react';
import Page from 'components/Page';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Label,
  Spinner,
  Modal,
  ModalBody,
} from 'reactstrap';
import classnames from 'classnames';
import axios from 'axios';
import StorageService from '../services/StorageService';
import { animateScroll as scroll } from 'react-scroll';
import ProjectForm from 'components/ProjectForm';
import SessionExpiredModal from '../components/SessionExpiredModal';

class SurveyPage extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      showModal: false,
      selectedItem: null,
      categoryCode: [],
      questionIndex: [],
      radioInput: [],
      checkInput: [],
      stageIndex: [],
      queryData: [],
      responses: [],
      questionID: [],
      answerID: [],
      error: false,
      hidden: false,
      loadForm: false,
      projectID: null,
      loadContent: false,
      divider: null,
      sessionPopup: false,
    };
  }

  showProjectModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  getData = () => {
    document.body.classList.add('no-sroll');
    const session_key = StorageService.getSession('user_info').session_key;
    const data = {
      session_key: session_key,
    };
    const { questionID } = this.state;
    let tempArr = [...questionID];

    this.setState({
      hidden: !this.state.hidden,
    });

    axios
      .post('https://www.projanalysis.com/api/get_question.php', data)
      .then(response => {
        document.body.classList.remove('no-sroll');
        const result = response.data;

        if (result.error == 'true' && result.message == 'Bad Request') {
          this.setState({
            sessionPopup: !this.state.sessionPopup,
          });
        }

        for (var stage in result.data) {
          for (var cat in result.data[stage].category) {
            for (var q in result.data[stage].category[cat]) {
              for (var qid in result.data[stage].category[cat].query) {
                if (
                  tempArr.indexOf(
                    result.data[stage].category[cat].query[qid].question_id,
                  ) == -1
                ) {
                  tempArr.push(
                    result.data[stage].category[cat].query[qid].question_id,
                  );
                }
              }
            }
          }
        }

        this.setState({
          questionID: tempArr,
          queryData: response.data.data,
          hidden: !this.state.hidden,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleRedirect = () => {
    StorageService.clearSession('user_info');
    this.props.history.push('/login');
  };

  checkLimit = () => {
    const user_info = StorageService.getSession('user_info');
    const data = {
      session_key: user_info.session_key,
    };

    axios
      .post('https://www.projanalysis.com/api/check_survey_limit.php', data)
      .then(response => {
        this.setState({
          loadContent: !this.state.loadContent,
        });

        const result = response.data;

        if (result.data.limit == 0) {
          this.setState({
            loadForm: !this.state.loadForm,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  loadData = id => {
    this.setState({
      projectID: id,
    });
    this.getData();
  };

  handleView() {
    this.props.history.push({
      pathname: '/',
    });
  }

  handleChange2(i, j, k, event) {
    const { id, name, value } = event.target;
    const category = event.target.getAttribute('data-name');
    const choice = event.target.getAttribute('data-tag');
    const query_id = event.target.getAttribute('data-query');

    const { answerID } = this.state;
    answerID.push(query_id);

    this.setState({
      categoryCode: [...this.state.categoryCode, id],
      questionIndex: [...this.state.questionIndex, query_id],
      radioInput: [...this.state.radioInput, name],
    });
    let responses = [...this.state.responses];

    if (
      this.state.categoryCode.indexOf(id) > -1 &&
      this.state.questionIndex.indexOf(query_id) > -1 &&
      this.state.radioInput.indexOf(name) > -1
    ) {
      responses.forEach(function(item, index) {
        if (item.answer[0].input == name) {
          responses.splice(index, 1);
        }
      });
    }
    responses.push({
      stage_id: this.state.queryData[j].stage_id,
      category_id: id,
      row_id: query_id,
      question_id: this.state.queryData[j].category[category].query[i].id,
      answer: [
        {
          input: name,
          choice_id: this.state.queryData[j].category[category].query[i].choice[
            choice
          ].answer[k].option[value].id,
          name: this.state.queryData[j].category[category].query[i].choice[
            choice
          ].answer[k].option[value].response.replace("'", ''),
          scoring: this.state.queryData[j].category[category].query[i].choice[
            choice
          ].answer[k].option[value].scoring,
        },
      ],
    });

    this.setState({ responses, answerID });
  }

  handleChangeC(i, j, k, event) {
    const { id, name, value } = event.target;
    const category = event.target.getAttribute('data-name');
    const choice = event.target.getAttribute('data-tag');
    const query_id = event.target.getAttribute('data-query');

    const { answerID } = this.state;
    answerID.push(query_id);

    this.setState({
      categoryCode: [...this.state.categoryCode, id],
      questionIndex: [...this.state.questionIndex, query_id],
      checkInput: [...this.state.checkInput, name],
    });

    let responses = [...this.state.responses];
    if (
      this.state.categoryCode.indexOf(id) > -1 &&
      this.state.questionIndex.indexOf(query_id) > -1 &&
      this.state.checkInput.indexOf(name) > -1
    ) {
      responses.forEach(function(item, index) {
        if (item.answer[0].choice_id == k) {
          responses.splice(index, 1);
        }
      });
    } else {
      responses.push({
        stage_id: this.state.queryData[j].stage_id,
        category_id: id,
        row_id: query_id,
        question_id: this.state.queryData[j].category[category].query[i].id,
        answer: [
          {
            input: name,
            choice_id: this.state.queryData[j].category[category].query[i]
              .choice[choice].answer[value].id,
            name: this.state.queryData[j].category[category].query[i].choice[
              choice
            ].answer[value].response.replace("'", ''),
            scoring: this.state.queryData[j].category[category].query[i].choice[
              choice
            ].answer[value].scoring,
          },
        ],
      });
    }

    this.setState({ responses, answerID });
  }

  handleChange(i, j, event) {
    const { id, name, value } = event.target;
    const category = event.target.getAttribute('data-name');
    const choice = event.target.getAttribute('data-tag');
    const query_id = event.target.getAttribute('data-query');
    const type = event.target.getAttribute('data-type');
    const multiple = event.target.getAttribute('data-multiple');
    const { answerID } = this.state;

    let responses = [...this.state.responses];

    if (type == 'A') {
      for (let i = responses.length - 1; i >= 0; i -= 1) {
        if (responses[i].row_id == query_id) {
          responses.splice(i, 1);
        }
      }

      answerID.forEach(function(item, index) {
        if (item == query_id) {
          answerID.splice(index, 1);
        }
      });
    }

    if (value != 99) {
      if (answerID.indexOf(query_id) == -1) {
        answerID.push(query_id);
      }

      responses.push({
        stage_id: this.state.queryData[j].stage_id,
        category_id: id,
        row_id: query_id,
        question_id: this.state.queryData[j].category[category].query[i].id,
        answer: [
          {
            input: name,
            choice_id: this.state.queryData[j].category[category].query[i]
              .choice[choice].answer[value].id,
            name: this.state.queryData[j].category[category].query[i].choice[
              choice
            ].answer[value].response.replace("'", ''),
            scoring: this.state.queryData[j].category[category].query[i].choice[
              choice
            ].answer[value].scoring,
          },
        ],
      });
    }

    this.setState({ responses, answerID });

    if (multiple == 1) {
      if (value == 99) {
        this.refs['option' + query_id].style.display = 'none';
      } else {
        document
          .querySelectorAll('div.rcoption' + query_id + " input[type='radio']")
          .forEach(el => (el.checked = false));
        document
          .querySelectorAll(
            'div.rcoption' + query_id + " input[type='checkbox']",
          )
          .forEach(el => (el.checked = false));
        this.refs['option' + query_id].style.display = 'block';
      }
    }
  }

  handleSubmit = event => {
    event.preventDefault();

    const user_info = StorageService.getSession('user_info');
    const self = this;

    const { questionID, answerID } = this.state;

    for (var ans in questionID) {
      const isError = answerID.includes(questionID[ans]);
      if (!isError) {
        alert(
          'Please complete all the questions before submit your survey. Thanks.',
        );
        return;
      }
    }

    const data = {
      session_key: user_info.session_key,
      email: user_info.email,
      project_id: this.state.projectID,
      answer: this.state.responses.sort((a, b) =>
        parseInt(a.row_id) > parseInt(b.row_id)
          ? 1
          : parseInt(a.row_id) === parseInt(b.row_id)
          ? parseInt(a.answer[0].choice_id) > parseInt(b.answer[0].choice_id)
            ? 1
            : -1
          : -1,
      ),
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/create_result.php', data)
      .then(response => {
        this.setState({
          hidden: false,
        });
        const result = response.data;
        if (result.error == 'true' && result.message != 'Success') {
          alert(result.message);
          return;
        }
        self.props.history.push('/statistics');
        alert('Your submission has been successful.');
      })
      .catch(error => {
        alert(error);
      });
  };

  componentDidMount() {
    // this.checkFormDone();

    const current_user = StorageService.getSession('user_info');
    if (current_user.type == 'Respondent') {
      this.checkLimit();
    } else {
      this.setState({
        loadForm: !this.state.loadForm,
      });
    }
  }

  toggle(tab) {
    scroll.scrollToTop();
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      activeTab,
      projectID,
      loadContent,
      loadForm,
      showModal,
      hidden,
    } = this.state;
    const load = loadContent ? {} : { display: 'none' };
    const externalCloseBtn = (
      <button
        className="close"
        style={{
          position: 'absolute',
          top: '15px',
          right: '20px',
          fontSize: '3rem',
          fontWeight: '400',
          color: '#fff',
          textShadow: 'none',
          opacity: '1',
        }}
        onClick={this.showProjectModal}
      >
        &times;
      </button>
    );
    if (!loadForm) {
      return (
        <Page
          className="QuestionnairePage"
          title="Questionnaire"
          breadcrumbs={[{ name: 'Questionnaire', active: true }]}
          style={load}
        >
          <Row>
            <Col md={12} sm={12} xs={12}>
              <Card>
                <CardBody>
                  <p>
                    You have already the survey limit. Please click the button
                    below to see your survey result.
                  </p>
                  <Button color="primary" onClick={() => this.handleView()}>
                    View your survey result
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Page>
      );
    }

    return (
      <Page
        className="QuestionnairePage page-content"
        title="Questionnaire"
        breadcrumbs={[{ name: 'Questionnaire', active: true }]}
      >
        <SessionExpiredModal
          isShow={this.state.sessionPopup}
          handleRedirect={this.handleRedirect}
        />
        <Modal
          isOpen={showModal}
          toggle={this.showProjectModal}
          size="md"
          backdrop="static"
          external={externalCloseBtn}
          centered
        >
          <ModalBody>
            <p>
              <strong>Set your project name</strong>
            </p>
            <ProjectForm
              history={this.props.history}
              toggleModal={this.showProjectModal}
              loadData={this.loadData}
            />
          </ModalBody>
        </Modal>
        <div className={'loading ' + (hidden ? '' : 'hidden')}>
          <Spinner type="grow" color="secondary" />
        </div>
        {projectID == null ? (
          <Button color="secondary" onClick={() => this.showProjectModal()}>
            Click here to start
          </Button>
        ) : (
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col md={12} lg={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === '1',
                      })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      Feasibility
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === '2',
                      })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      Design
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === '3',
                      })}
                      onClick={() => {
                        this.toggle('3');
                      }}
                    >
                      Procurement
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === '4',
                      })}
                      onClick={() => {
                        this.toggle('4');
                      }}
                    >
                      Construction
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  {this.state.queryData.length > 0 &&
                    this.state.queryData.map((stage, first_index) => {
                      return (
                        <TabPane
                          tabId={first_index + 1}
                          key={first_index}
                          className={
                            activeTab == first_index + 1 ? 'active' : ''
                          }
                        >
                          <Card className="mb-4">
                            <CardHeader>{stage.stage_name}</CardHeader>
                            <CardBody>
                              {stage.category.map((cat, second_index) => {
                                return (
                                  <div key={second_index}>
                                    <p key={cat.category_id}>
                                      <strong>{cat.category_name}</strong>
                                    </p>
                                    {cat.query.map((q, third_index) => {
                                      return (
                                        <div key={third_index}>
                                          <p key={q.question_id}>
                                            {q.question_name}
                                          </p>
                                          <FormGroup row>
                                            <Col sm={12} lg={6}>
                                              {q.choice.map(
                                                (input, fourth_index) => {
                                                  if (input.type == 'A') {
                                                    return (
                                                      <Input
                                                        className="mb-2"
                                                        key={fourth_index}
                                                        type="select"
                                                        required="required"
                                                        id={cat.category_id}
                                                        name={second_index}
                                                        data-name={second_index}
                                                        data-tag={fourth_index}
                                                        data-query={
                                                          q.question_id
                                                        }
                                                        data-type={input.type}
                                                        data-multiple={
                                                          q.multiple
                                                        }
                                                        onChange={this.handleChange.bind(
                                                          this,
                                                          third_index,
                                                          first_index,
                                                        )}
                                                      >
                                                        <option value="99">
                                                          Please select---
                                                        </option>
                                                        {input.answer.map(
                                                          (
                                                            choice,
                                                            answer_index,
                                                          ) => {
                                                            return (
                                                              <option
                                                                key={
                                                                  answer_index
                                                                }
                                                                value={
                                                                  answer_index
                                                                }
                                                              >
                                                                {
                                                                  choice.response
                                                                }
                                                              </option>
                                                            );
                                                          },
                                                        )}
                                                      </Input>
                                                    );
                                                  }

                                                  if (input.type == 'B') {
                                                    return (
                                                      <div
                                                        className={
                                                          `wrapper-box rcoption` +
                                                          q.question_id
                                                        }
                                                        key={fourth_index}
                                                      >
                                                        <div
                                                          className="disabled-box"
                                                          ref={
                                                            'option' +
                                                            q.question_id
                                                          }
                                                        ></div>
                                                        {input.answer.map(
                                                          (
                                                            group,
                                                            group_index,
                                                          ) => {
                                                            return (
                                                              <div
                                                                key={
                                                                  group_index
                                                                }
                                                                className="mb-2"
                                                              >
                                                                {group.option.map(
                                                                  (
                                                                    choice,
                                                                    answer_index,
                                                                  ) => {
                                                                    return (
                                                                      <FormGroup
                                                                        check
                                                                        key={
                                                                          answer_index
                                                                        }
                                                                      >
                                                                        <Label
                                                                          check
                                                                        >
                                                                          <Input
                                                                            type="radio"
                                                                            name={`radio${q.question_id}_${group_index}`}
                                                                            id={
                                                                              cat.category_id
                                                                            }
                                                                            data-name={
                                                                              second_index
                                                                            }
                                                                            data-tag={
                                                                              fourth_index
                                                                            }
                                                                            data-query={
                                                                              q.question_id
                                                                            }
                                                                            data-type={
                                                                              input.type
                                                                            }
                                                                            data-multiple={
                                                                              q.multiple
                                                                            }
                                                                            value={
                                                                              answer_index
                                                                            }
                                                                            onChange={this.handleChange2.bind(
                                                                              this,
                                                                              third_index,
                                                                              first_index,
                                                                              group_index,
                                                                            )}
                                                                          />
                                                                          {
                                                                            choice.response
                                                                          }
                                                                        </Label>
                                                                      </FormGroup>
                                                                    );
                                                                  },
                                                                )}
                                                              </div>
                                                            );
                                                          },
                                                        )}
                                                      </div>
                                                    );
                                                  }

                                                  if (input.type == 'C') {
                                                    return (
                                                      <div
                                                        className={
                                                          `wrapper-box rcoption` +
                                                          q.question_id
                                                        }
                                                        key={fourth_index}
                                                      >
                                                        <div
                                                          className="disabled-box"
                                                          ref={
                                                            'option' +
                                                            q.question_id
                                                          }
                                                        ></div>
                                                        {input.answer.map(
                                                          (
                                                            choice,
                                                            answer_index,
                                                          ) => {
                                                            return (
                                                              <FormGroup
                                                                check
                                                                key={
                                                                  answer_index
                                                                }
                                                              >
                                                                <Label check>
                                                                  <Input
                                                                    type="checkbox"
                                                                    name={`check${q.question_id}_${answer_index}`}
                                                                    value={
                                                                      answer_index
                                                                    }
                                                                    id={
                                                                      cat.category_id
                                                                    }
                                                                    data-name={
                                                                      second_index
                                                                    }
                                                                    data-tag={
                                                                      fourth_index
                                                                    }
                                                                    data-query={
                                                                      q.question_id
                                                                    }
                                                                    data-type={
                                                                      input.type
                                                                    }
                                                                    data-multiple={
                                                                      q.multiple
                                                                    }
                                                                    onChange={this.handleChangeC.bind(
                                                                      this,
                                                                      third_index,
                                                                      first_index,
                                                                      choice.id,
                                                                    )}
                                                                  />
                                                                  {
                                                                    choice.response
                                                                  }
                                                                </Label>
                                                              </FormGroup>
                                                            );
                                                          },
                                                        )}
                                                      </div>
                                                    );
                                                  }

                                                  if (input.type == 'D') {
                                                    return (
                                                      <div key={fourth_index}>
                                                        <div
                                                          className="disabled-box"
                                                          ref={
                                                            'option' +
                                                            q.question_id
                                                          }
                                                        ></div>
                                                        <FormGroup>
                                                          <Input
                                                            className="mb-2"
                                                            key={fourth_index}
                                                            type="select"
                                                            required="required"
                                                            id={cat.category_id}
                                                            name={second_index}
                                                            data-name={
                                                              second_index
                                                            }
                                                            data-tag={
                                                              fourth_index
                                                            }
                                                            data-query={
                                                              q.question_id
                                                            }
                                                            data-type={
                                                              input.type
                                                            }
                                                            data-multiple={
                                                              q.multiple
                                                            }
                                                            onChange={this.handleChange.bind(
                                                              this,
                                                              third_index,
                                                              first_index,
                                                            )}
                                                          >
                                                            <option value="99">
                                                              Please select---
                                                            </option>
                                                            {input.answer.map(
                                                              (
                                                                choice,
                                                                answer_index,
                                                              ) => {
                                                                return (
                                                                  <option
                                                                    key={
                                                                      answer_index
                                                                    }
                                                                    value={
                                                                      answer_index
                                                                    }
                                                                  >
                                                                    {
                                                                      choice.response
                                                                    }
                                                                  </option>
                                                                );
                                                              },
                                                            )}
                                                          </Input>
                                                        </FormGroup>
                                                      </div>
                                                    );
                                                  }
                                                },
                                              )}
                                            </Col>
                                          </FormGroup>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </CardBody>
                          </Card>
                          <>
                            {first_index != 0 && (
                              <Button
                                color="secondary"
                                onClick={() => {
                                  this.toggle(
                                    (parseInt(activeTab) - 1).toString(),
                                  );
                                }}
                              >
                                Prev -{' '}
                                {
                                  this.state.queryData[first_index - 1]
                                    .stage_name
                                }
                              </Button>
                            )}{' '}
                            {first_index + 1 < this.state.queryData.length && (
                              <Button
                                color="secondary"
                                onClick={() => {
                                  this.toggle(
                                    (parseInt(activeTab) + 1).toString(),
                                  );
                                }}
                              >
                                Next -{' '}
                                {
                                  this.state.queryData[first_index + 1]
                                    .stage_name
                                }
                              </Button>
                            )}
                          </>
                          {first_index + 1 == this.state.queryData.length && (
                            <Button type="submit" color="primary">
                              Submit Your Answer
                            </Button>
                          )}
                        </TabPane>
                      );
                    })}
                </TabContent>
              </Col>
            </Row>
          </Form>
        )}
      </Page>
    );
  }
}

export default SurveyPage;
