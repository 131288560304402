import React from 'react';
const ChartLegend = () => {
  return (
    <div className="legend">
      <ul>
        <li>
          1<sup>st</sup> Quartile
        </li>
        <li>
          2<sup>nd</sup> Quartile
        </li>
        <li>
          3<sup>rd</sup> Quartile
        </li>
        <li>
          4<sup>th</sup> Quartile
        </li>
        <li>Your Result</li>
      </ul>
    </div>
  );
};

export default ChartLegend;
