import Page from 'components/Page';
import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Spinner,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import axios from 'axios';
import StorageService from '../services/StorageService';
import SessionExpiredModal from '../components/SessionExpiredModal';

class HomePage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      responseData: [],
      project: {},
      sessionPopup: false,
    };

    this.goToQuestionnaire = this.goToQuestionnaire.bind(this);
  }

  goToQuestionnaire() {
    this.props.history.push('/questionnaire');
  }

  // handleView(respondent) {
  //   this.props.history.push({
  //     pathname: '/statistics',
  //     state: { data: respondent },
  //   });
  // }

  handleView() {
    // console.log(this.state.project);
    // this.props.history.push({
    //   pathname: '/statistics',
    //   state: { data: respondent },
    // });
  }

  handleView2(project) {
    const user = StorageService.getSession('user_info');

    this.props.history.push({
      pathname: '/statistics',
      state: {
        data: {
          email: user.email,
          name: user.name,
          project_id: project.project_id,
          project_name: project.project_name,
        },
      },
    });
  }

  delete = id => {
    const session_key = StorageService.getSession('user_info').session_key;
    const data = {
      session_key: session_key,
      user_id: id,
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/delete_registered_user.php', data)
      .then(response => {
        this.setState({ hidden: false });
        const result = response.data;

        if (result.error == 'true' && result.message == 'Bad Request') {
          this.setState({
            sessionPopup: !this.state.sessionPopup,
          });
        }

        if (result.error == 'true' && result.message != 'Success') {
          alert('Failed to delete. Please try again.');
          return;
        }
        alert('Record deleted successfully.');
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  };

  getData() {
    document.body.classList.add('no-sroll');
    const session_key = StorageService.getSession('user_info').session_key;
    const data = {
      session_key: session_key,
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/get_response.php', data)
      .then(response => {
        document.body.classList.remove('no-sroll');

        const result = response.data;
        if (result.error == 'true' && result.message == 'Bad Request') {
          this.setState({
            sessionPopup: !this.state.sessionPopup,
          });
        }

        if (this._isMounted) {
          this.setState({ responseData: response.data.data, hidden: false });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  getSurveyList() {
    document.body.classList.add('no-sroll');
    const current_user = StorageService.getSession('user_info');
    const data = {
      session_key: current_user.session_key,
      client_id: current_user.email,
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/get_survey_list.php', data)
      .then(response => {
        document.body.classList.remove('no-sroll');
        if (this._isMounted) {
          this.setState({ responseData: response.data.data, hidden: false });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  changeHandler = event => {
    const { name, value } = event.target;
    const { responseData } = this.state;

    this.setState({
      project: {
        email: responseData[name].client_id,
        name: responseData[name].client_name,
        project_id: responseData[name].project[value].project_id,
        project_name: responseData[name].project[value].project_name,
      },
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    if (Object.keys(this.state.project).length == 0) {
      alert('Please select a project.');
      return;
    }

    this.props.history.push({
      pathname: '/statistics',
      state: { data: this.state.project },
    });
  };

  handleRedirect = () => {
    StorageService.clearSession('user_info');
    this.props.history.push('/login');
  };

  componentDidMount() {
    this._isMounted = true;
    const current_user = StorageService.getSession('user_info');
    if (current_user.type == 'Respondent') {
      this.getSurveyList();
    } else {
      this.getData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const current_user = StorageService.getSession('user_info');
    if (current_user.type == 'Respondent') {
      return (
        <Page
          title="Dashboard"
          breadcrumbs={[{ name: 'dashboard', active: true }]}
        >
          <SessionExpiredModal
            isShow={this.state.sessionPopup}
            handleRedirect={this.handleRedirect}
          />
          <div className={'loading ' + (this.state.hidden ? '' : 'hidden')}>
            <Spinner type="grow" color="secondary" />
          </div>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Card>
                <CardHeader>Your Survey List</CardHeader>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Project Name</th>
                        <th>Created at</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.responseData.length > 0 &&
                        this.state.responseData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.project_name}</td>
                              <td>{item.created_at}</td>
                              <td>
                                <Button
                                  color="primary"
                                  onClick={() => this.handleView2(item)}
                                >
                                  View
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Page>
      );
    }

    return (
      <Page
        title="Dashboard"
        breadcrumbs={[{ name: 'dashboard', active: true }]}
      >
        <div className={'loading ' + (this.state.hidden ? '' : 'hidden')}>
          <Spinner type="grow" color="secondary" />
        </div>
        {current_user.type == 'Respondent' && (
          <Row>
            <Col lg={12} md={12} className="text-right mb-3">
              <Button color="secondary" onClick={this.goToQuestionnaire}>
                Answer survey question
              </Button>
            </Col>
          </Row>
        )}

        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <Card>
              <CardHeader>Survey Respondent List</CardHeader>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Survey Taken</th>
                      {current_user.type == 'Superadmin' && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.responseData.length > 0 &&
                      this.state.responseData.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            style={{
                              backgroundColor:
                                current_user.email == item.client_id
                                  ? '#e2e2e2'
                                  : '',
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>{item.client_name}</td>
                            <td>{item.client_id}</td>
                            <td>{item.count}</td>
                            <td style={{ display: 'flex' }}>
                              {item.count > 0 && (
                                <>
                                  <Form inline onSubmit={this.handleSubmit}>
                                    <FormGroup>
                                      <Input
                                        type="select"
                                        name={index}
                                        // value={this.state.project_name}
                                        onChange={this.changeHandler}
                                        style={{ marginRight: '5px' }}
                                      >
                                        <option value="99">
                                          Please select---
                                        </option>
                                        {item.project.map(
                                          (project, p_index) => {
                                            return (
                                              <option
                                                key={p_index}
                                                value={p_index}
                                              >
                                                {project.project_name}
                                              </option>
                                            );
                                          },
                                        )}
                                      </Input>
                                    </FormGroup>
                                    <Button
                                      color="secondary"
                                      style={{ marginRight: '5px' }}
                                    >
                                      View
                                    </Button>
                                  </Form>
                                </>
                              )}
                              {current_user.type == 'Superadmin' && (
                                <Button
                                  color="primary"
                                  style={{ marginRight: '5px' }}
                                  onClick={() => this.delete(item.client_id)}
                                >
                                  Delete
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default HomePage;
