import Page from 'components/Page';
import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Modal,
  ModalBody,
  Spinner,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from 'reactstrap';
import axios from 'axios';
import StorageService from '../services/StorageService';
import { Redirect } from 'react-router';
import SessionExpiredModal from '../components/SessionExpiredModal';

class SurveyTakenLimitPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
      number_of_response: '',
      sessionPopup: false,
    };
  }

  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const user_info = StorageService.getSession('user_info');
    const data = {
      session_key: user_info.session_key,
      number: this.state.number_of_response,
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/update_survey_limit.php', data)
      .then(response => {
        this.setState({ hidden: false });
        const result = response.data;

        if (result.error == 'true' && result.message == 'Bad Request') {
          this.setState({
            sessionPopup: !this.state.sessionPopup,
          });
        }

        if (result.error == 'true' && result.message != 'Success') {
          alert(result.message);
          return;
        }

        alert('Survey limit is updated successfully.');
        return;
      })
      .catch(error => {
        alert(error);
      });
  };

  handleRedirect = () => {
    StorageService.clearSession('user_info');
    this.props.history.push('/login');
  };

  getData() {
    const current_user = StorageService.getSession('user_info');
    const data = {
      session_key: current_user.session_key,
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/get_survey_limit.php', data)
      .then(response => {
        document.body.classList.remove('no-sroll');
        this.setState({
          number_of_response: response.data.data.limit,
          hidden: false,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    const current_user = StorageService.getSession('user_info');

    if (current_user.type === 'Respondent') {
      return <Redirect to="/" />;
    }

    return (
      <Page title="Settings" breadcrumbs={[{ name: 'Settings', active: true }]}>
        <SessionExpiredModal
          isShow={this.state.sessionPopup}
          handleRedirect={this.handleRedirect}
        />
        <div className={'loading ' + (this.state.hidden ? '' : 'hidden')}>
          <Spinner type="grow" color="secondary" />
        </div>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <Card>
              <CardHeader>Survey NumberLimit</CardHeader>
              <CardBody>
                <p style={{ width: '70%', color: 'grey', fontSize: '14px' }}>
                  Set your survey limit number taken for each respondent, e.g
                  each respondent can only take total of 5 surveys for different
                  projects.
                </p>
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup row>
                    <Label for="no_of_response" sm={2}>
                      Survey limit number
                    </Label>
                    <Col sm={3}>
                      <Input
                        type="number"
                        name="number_of_response"
                        // placeholder="5"
                        min="1"
                        value={this.state.number_of_response}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </FormGroup>

                  <Button>Save</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default SurveyTakenLimitPage;
