import React from 'react';
import PageWrapper from 'components/Page';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Button,
} from 'reactstrap';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Edit,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import axios from 'axios';
import StorageService from '../services/StorageService';
import { Redirect } from 'react-router';
import SessionExpiredModal from '../components/SessionExpiredModal';

class WeightPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      weightData: [],
      sessionPopup: false,
    };
    this.editSettings = {
      allowEditing:
        StorageService.getSession('user_info').type == 'DP Admin' ||
        StorageService.getSession('user_info').type == 'Superadmin'
          ? true
          : false,
      allowAdding: false,
      allowDeleting: false,
      newRowPosition: 'Top',
    };
    this.editparams = { params: { popupHeight: '300px' } };
    this.validationRule = { required: true };
    this.orderidRules = { required: true, number: true };
    this.pageSettings = { pageCount: 5 };
  }

  actionBegin(args) {
    if (args.requestType === 'save') {
      args.data.session_key = StorageService.getSession(
        'user_info',
      ).session_key;
      axios
        .post(
          'https://www.projanalysis.com/api/update_weight.php',
          JSON.stringify(args.data),
        )
        .then(response => {
          const result = response.data;
          if (result.error == 'true') {
            if (result.message == 'No updated needed') {
              return;
            } else {
              alert(result.message);
            }
          } else {
            alert('Updated Successfully');
          }
          window.location.reload();
        })
        .catch(error => {
          throw new Error(error);
        });
    }
  }

  getData() {
    document.body.classList.add('no-sroll');
    const session_key = StorageService.getSession('user_info').session_key;
    const data = {
      session_key: session_key,
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/get_weight.php', data)
      .then(response => {
        document.body.classList.remove('no-sroll');
        const result = response.data;
        if (result.error == 'true' && result.message == 'Bad Request') {
          this.setState({
            sessionPopup: !this.state.sessionPopup,
          });
        }
        this.setState({ weightData: response.data.data, hidden: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleRedirect = () => {
    StorageService.clearSession('user_info');
    this.props.history.push('/login');
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const current_user = StorageService.getSession('user_info');

    if (current_user.type === 'Respondent') {
      return <Redirect to="/" />;
    }

    return (
      <PageWrapper
        className="WeightPage"
        title="Weight"
        breadcrumbs={[{ name: 'Weight', active: true }]}
      >
        <SessionExpiredModal
          isShow={this.state.sessionPopup}
          handleRedirect={this.handleRedirect}
        />
        <div className={'loading ' + (this.state.hidden ? '' : 'hidden')}>
          <Spinner type="grow" color="secondary" />
        </div>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <CardHeader>Weight</CardHeader>
              <CardBody>
                <GridComponent
                  dataSource={this.state.weightData}
                  ref={grid => (this.gridInstance = grid)}
                  allowPaging={true}
                  editSettings={this.editSettings}
                  pageSettings={this.pageSettings}
                  actionBegin={this.actionBegin.bind(this)}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="category_code"
                      headerText="Cat Code"
                      width="140"
                    ></ColumnDirective>
                    <ColumnDirective
                      field="id"
                      headerText="ID"
                      width="140"
                      textAlign="Right"
                    ></ColumnDirective>
                    <ColumnDirective
                      field="weight_tm"
                      headerText="TM"
                      format="N2"
                      width="140"
                      editType="numericedit"
                      textAlign="Right"
                    ></ColumnDirective>
                    <ColumnDirective
                      field="weight_cost"
                      headerText="COST"
                      format="N2"
                      width="140"
                      editType="numericedit"
                      textAlign="Right"
                    ></ColumnDirective>
                    <ColumnDirective
                      field="weight_qlty"
                      headerText="QLTY"
                      format="N2"
                      width="140"
                      editType="numericedit"
                      textAlign="Right"
                    ></ColumnDirective>
                    <ColumnDirective
                      field="weight_rep"
                      headerText="REP"
                      format="N2"
                      width="140"
                      editType="numericedit"
                      textAlign="Right"
                    ></ColumnDirective>
                    <ColumnDirective
                      field="weight_ret"
                      headerText="RET"
                      format="N2"
                      width="140"
                      editType="numericedit"
                      textAlign="Right"
                    ></ColumnDirective>
                  </ColumnsDirective>
                  <Inject services={[Page, Toolbar, Edit]} />
                </GridComponent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </PageWrapper>
    );
  }
}

export default WeightPage;
