import React from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Spinner,
} from 'reactstrap';
import Page from 'components/Page';
import axios from 'axios';
import StorageService from '../services/StorageService';
import { Redirect } from 'react-router';
import SessionExpiredModal from '../components/SessionExpiredModal';

class KpiPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      kpiData: [],
      sessionPopup: false,
    };
  }

  getData() {
    document.body.classList.add('no-sroll');
    const session_key = StorageService.getSession('user_info').session_key;
    const data = {
      session_key: session_key,
    };

    this.setState({
      hidden: true,
    });

    axios
      .post('https://www.projanalysis.com/api/get_kpi.php', data)
      .then(response => {
        document.body.classList.remove('no-sroll');
        const result = response.data;
        if (result.error == 'true' && result.message == 'Bad Request') {
          this.setState({
            sessionPopup: !this.state.sessionPopup,
          });
        }
        this.setState({ kpiData: response.data.data, hidden: false });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleRedirect = () => {
    StorageService.clearSession('user_info');
    this.props.history.push('/login');
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const current_user = StorageService.getSession('user_info');

    return (
      <Page
        className="KpiPage"
        title="KPI"
        breadcrumbs={[{ name: 'KPI', active: true }]}
      >
        <SessionExpiredModal
          isShow={this.state.sessionPopup}
          handleRedirect={this.handleRedirect}
        />
        <div className={'loading ' + (this.state.hidden ? '' : 'hidden')}>
          <Spinner type="grow" color="secondary" />
        </div>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <CardHeader>KPI Guide</CardHeader>
              <CardBody>
                <div className="container-wrapper">
                  <div className="inner-grid">
                    <Table size="sm" bordered>
                      <thead>
                        <tr>
                          <th>Code</th>
                          <th>KPI</th>
                          <th>+2</th>
                          <th>+1</th>
                          <th>-1</th>
                          <th>-2</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.kpiData.length > 0 &&
                          this.state.kpiData.slice(0, 5).map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.kpi_code}</td>
                                <td>{item.kpi_name}</td>
                                {item.kpi_desc.map((desc, index) => {
                                  return (
                                    <td key={index}>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: desc.desc,
                                        }}
                                      />
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <br />
                <Table size="sm" bordered>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>KPI</th>
                      <th>+2</th>
                      <th>+1</th>
                      <th>-1</th>
                      <th>-2</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.kpiData.length > 0 &&
                      this.state.kpiData.slice(5, 6).map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.kpi_code}</td>
                            <td>{item.kpi_name}</td>
                            {item.kpi_desc.map((desc, index) => {
                              return <td key={index}>{desc.desc}</td>;
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default KpiPage;
