import React from 'react';
import { Card, CardBody, Button } from 'reactstrap';

class SessionExpiredModal extends React.Component {
  render() {
    return (
      <div className={'alertPopup ' + (this.props.isShow ? '' : 'hidden')}>
        <Card>
          <CardBody>
            <p>Session expired. Please login again.</p>
            <Button color="primary" onClick={this.props.handleRedirect}>
              OK
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default SessionExpiredModal;
